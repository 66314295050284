<template>
  <TabbedPage>
    <template v-slot:nav-tabs
      ><NavTab
        v-for="tab in contentTabs"
        :key="tab.title"
        :href="tab.path"
        :label="$t(tab.title)"
        :german-only="tab.germanOnly"
    /></template>
    <template v-slot:main-content>
      <router-view :ranked-games="rankedGames"
    /></template>
  </TabbedPage>
</template>

<script>
import NavTab from "@/components/base/nav-tab.vue";
import TabbedPage from "@/views/TabbedPage.vue";
import { listTrophies } from "@/API/achievements.js";

export default {
  name: "UserAchievementsPage",
  components: {
    NavTab,
    TabbedPage,
  },
  data() {
    return {
      contentTabs: [
        {
          title: "lobby.navigation.achievements",
          path: "/profile/achievements",
        },
        {
          title: "achievements.top_games",
          path: "/profile/achievements/trophies",
        },
      ],
      rankedGames: [],
    };
  },
  mounted() {
    this.$store
      .dispatch("achievements/fetchAchievements")
      .then(() => {
        listTrophies()
          .then((res) => {
            this.rankedGames = res.data.data.trophies;
          })
          .catch((e) => {
            this.error = true;
            console.log(e);
          })
          .finally(() => {
            this.$store.commit("setIsPageLoading", false);
            this.loading = false;
          });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => this.$store.commit("setIsPageLoading", false));
  },
};
</script>
<style lang="scss">
.site-achievements {
  .spinner-container {
    position: fixed;
  }
}
</style>
