<template>
  <b-row
    v-if="numberToReach && includeAchievement"
    id="achievement-row"
    no-gutters
    align-h="around"
  >
    <b-col
      id="achievement-description"
      cols="3"
      class="achievement-col d-flex flex-column"
    >
      <div class="achievement-title bold text-uppercase">
        {{ $t(`achievements.achievement_title.${achievement.slug}`) }}
      </div>
      <div
        class="achievement-description text-white text-uppercase medium mt-2"
      >
        {{ description }}
      </div>
    </b-col>

    <b-col
      id="achievement-lights"
      cols="2"
      class="achievement-col position-relative d-flex flex-column justify-content-between align-items-start"
    >
      <span class="medium text-uppercase description">{{
        $t("tournaments.achievements.level")
      }}</span>

      <div v-if="currentLevel === 10" class="flag to-center-abs">
        <div class="flag-text golden-gradient-text">
          {{ $t("tournaments.achievements.banner_text1") }}
          <br />
          {{ $t("tournaments.achievements.banner_text2") }}
        </div>
      </div>
      <div class="light-row-container d-flex flex-column">
        <div class="light-row-top d-flex">
          <template v-for="(steps, index) in levelSteps1">
            <div :key="index" class="light-container">
              <div
                class="light to-center-abs"
                :class="index < currentLevel ? 'on' : 'off'"
              ></div>
            </div>
          </template>
        </div>
        <div class="light-row-bottom d-flex mt-1">
          <template v-for="(steps, index) in levelSteps2">
            <div :key="index" class="light-container">
              <div
                class="light to-center-abs"
                :class="index + 5 < currentLevel ? 'on' : 'off'"
              ></div>
            </div>
          </template>
        </div>
      </div>
    </b-col>
    <b-col
      id="achievement-progess"
      class="achievement-col position-relative d-flex flex-column align-items-start justify-content-between"
    >
      <span class="medium text-uppercase description">{{
        $t("tournaments.info.progress")
      }}</span>
      <template v-if="currentLevel <= 9">
        <div
          class="diagram-progress text-center bold flex-column justify-content-start align-items-center blue"
          :style="{ width: userProgress + '%' }"
        ></div>
        <span class="blue bold progress-number">
          {{ progressPercentage }}%</span
        ></template
      >

      <div v-else class="diagram-progress" style="width: 100%"></div>
    </b-col>
    <b-col
      v-if="currentLevel <= 9"
      id="next-achievement"
      class="achievement-col position-relative d-flex flex-column align-items-start justify-content-between"
    >
      <span class="medium text-uppercase description">
        {{
          $t("tournaments.achievements.achievement_level_text_new", {
            0: nextLevel,
          })
        }}
      </span>
      <div class="next-achievement-reward-box d-inline-flex align-items-center">
        <div class="text-white bold glow">
          {{ formatNumber(nextLevelReward.gold) }}
        </div>
        <div class="coin-icon">
          <img src="@/assets/img/common/coin1.png" class="w-100" />
        </div>
      </div>
    </b-col>

    <b-col
      v-if="
        achievement.friend_achievement &&
        achievement.friend_achievement.level > 0
      "
      id="top-friend"
      class="achievement-col position-relative d-flex flex-column align-items-start justify-content-between"
      ><span class="medium text-uppercase description">{{
        $t("tournaments.info.friend")
      }}</span>
      <div class="w-100 h-75 d-inline-flex align-items-center mt-1">
        <div
          class="avatar-container gradient-indent glow"
          :class="{ inactive: achievement.friend_achievement.user.deleted_at }"
          @click="
            $emit('topFriendClicked', achievement.friend_achievement.user.id)
          "
        >
          <img
            v-if="!achievement.friend_achievement.user.deleted_at"
            :src="achievement.friend_achievement.user.avatar_url"
            class="top-friend-avatar dead-center"
            draggable="false"
          />
        </div>
        <div
          class="top-friend-info-text d-flex flex-column align-items-start justify-content-center text-white text-uppercase medium"
        >
          <p class="m-0">
            {{ shortenString(achievement.friend_achievement.user.name, 20) }}
          </p>
          <p v-if="achievement.friend_achievement.user.deleted_at">
            ---deleted---
          </p>
          <span class="text-white text-uppercase medium">
            {{ $t("tournaments.achievements.achievement") }}
            {{ $t("tournaments.achievements.level") }}
            {{ achievement.friend_achievement.level }}
          </span>
        </div>
      </div>
    </b-col>
    <b-col v-else></b-col>
  </b-row>
</template>
<script>
export default {
  name: "AchievementRow",
  props: {
    achievement: {
      type: Object,
      required: true,
    },
    inactiveAchievements: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    numberToReach() {
      if (!this.achievement.user_achievement) {
        return this.achievement.level_steps[1];
      }
      let sum = 0;
      let levelToReach = this.currentLevel + 1;

      for (let i = 1; i <= levelToReach; i++) {
        sum += parseInt(this.achievement.level_steps[i]);
      }
      return sum;
    },
    includeAchievement() {
      return !this.inactiveAchievements.includes(this.achievement.slug);
    },

    levelSteps1() {
      return Object.values(this.achievement.level_steps).slice(0, 5);
    },
    levelSteps2() {
      return Object.values(this.achievement.level_steps).slice(5, 10);
    },
    progressPercentage() {
      return Math.floor(this.achievement.user_progress);
    },
    currentLevel() {
      if (!this.achievement.user_achievement) {
        return 0;
      }
      return this.achievement.user_achievement.level;
    },
    nextLevel() {
      return this.currentLevel + 1;
    },
    levelRewards() {
      return Object.values(this.achievement.level_rewards);
    },
    nextLevelReward() {
      return this.levelRewards[this.currentLevel];
    },
    userProgress() {
      if (this.achievement.user_progress <= 100) {
        return this.achievement.user_progress;
      } else {
        return 100;
      }
    },
    userAchievement() {
      if (!this.achievement.user_achievement) {
        return 0;
      } else {
        return this.achievement.user_achievement.level;
      }
    },
    description() {
      let result = "";
      if (this.achievement.slug === "joinleaguex") {
        result = this.$t(
          `achievements.achievement_task.joinleaguex.level_${this.userAchievement}`
        );
      }
      if (this.achievement.slug !== "joinleaguex") {
        result = this.$t(
          `achievements.achievement_task.${this.achievement.slug}>other`,
          {
            0: this.formatNumber(this.numberToReach),
          }
        );
        if (this.numberToReach === 1) {
          result = this.$t(
            `achievements.achievement_task.${this.achievement.slug}>one`,
            {
              0: this.formatNumber(this.numberToReach),
            }
          );
        }
      }

      return result;
    },
  },
};
</script>
<style lang="scss">
#achievement-row {
  width: 100%;
  height: 6.25rem;
  background-color: $dark-blue-06;
  position: relative;
  height: 7.688rem;
  margin-bottom: 1rem;
  padding: 1.313rem 1.25rem 1rem 1.063rem;
  &.not-yet {
    filter: grayscale(100%);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ */
      &:before {
        content: "";
        background-color: rgba(136, 136, 136, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  p,
  .achievement-col {
    font-size: 1rem;
  }

  .star-icon {
    position: absolute;
    right: -0.063rem;
    top: 0;
    width: 41pt;
  }

  .achievement-title {
    font-size: 1.5rem;
    color: #fff8b7;
    line-height: 1;
  }
  .achievement-description {
    font-size: 1.125rem;
    line-height: 1.2;
  }
  .achievement-col {
    padding-bottom: 1.25rem;
    padding-right: 0.313rem;

    .description {
      color: #8e8e8e;
      margin-bottom: 0.313rem;
    }
  }

  .flag {
    width: 7.688rem;
    height: 79.438rem;
    background-image: url(~@/assets/img/tournament/flag6X@3x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 2;
    top: 0;

    .flag-text {
      font-family: cinzel-decorative-black;
      font-size: 0.813rem;
      text-align: center;
      margin-top: 0.625rem;
    }
  }
  .light-row-container {
    margin: 0.375rem 0 0.375rem -0.313rem;
    .light-container {
      position: relative;
      margin: 0 0.125rem 0 0.125rem;
      height: 1.313rem;
      width: 1.313rem;
    }
    .light {
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;

      &.off {
        background: #050d1c;
        box-shadow: 0 0.125rem 0 0 #038fa7;
      }
      &.on {
        background-image: linear-gradient(179deg, #2dac00 7%, #228000 100%);
        box-shadow: inset 0 0 0.125rem 0 #43ff00, inset 0 0 0.25rem 0 #43ff00,
          inset 0 0 0.375rem 0 rgba(67, 255, 0, 0.43),
          inset 0 0 0.5rem 0 rgba(67, 255, 0, 0.87),
          inset 0 0 0.5rem 0 rgba(67, 255, 0, 0.87),
          inset 0 0 0.5rem 0 rgba(67, 255, 0, 0.87);
      }
    }
  }

  #achievement-progess {
    max-width: 10rem;
    padding-right: 3.75rem;
  }

  .diagram-progress {
    max-width: 8.875rem;
    height: 0.5rem;
    background-color: $light-blue;
    box-shadow: 0 0 0.5rem rgba(77, 229, 255, 0.58),
      0 0 0.5rem rgba(77, 229, 255, 0.58), 0 0 0.25rem rgba(77, 229, 255, 0.58);
  }
  .progress-number {
    font-size: 1.125rem;
    text-shadow: 0 0 1rem rgba(77, 229, 255, 0.6),
      0 0 0.5rem rgba(77, 229, 255, 0.6), 0 0 0.5rem rgba(77, 229, 255, 0.6),
      0 0 0.25rem rgba(0, 94, 110, 0.8), 0 0 0.25rem rgba(0, 94, 110, 0.8);
  }
  .text-white {
    &.glow {
      font-size: 2.25rem;
      text-shadow: 0 0 1rem rgba(77, 229, 255, 0.6),
        0 0 0.5rem rgba(77, 229, 255, 0.6), 0 0 0.5rem rgba(77, 229, 255, 0.6),
        0 0 0.25rem rgba(0, 94, 110, 0.8), 0 0 0.25rem rgba(0, 94, 110, 0.8);
    }
  }

  .next-achievement-subline {
    font-size: 0.75rem;
  }

  .coin-icon {
    width: 2rem;
    margin-left: 0.5rem;
    filter: drop-shadow(0 0.125rem 0.25rem black);
  }

  .avatar-container {
    position: relative;
    width: 3rem;
    height: 3rem;
    background: #050d1c;
    box-shadow: 0 0.125rem 0 0 #038fa7;
    border-radius: 0.25rem;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      filter: $hover-shadow;
    }
    &.inactive {
      pointer-events: none;
    }

    .top-friend-avatar {
      max-height: 100%;
      width: 100%;
      left: 0.125rem;
      mask-image: url(~@/assets/img/achievements/achieve-avatar-frame-mask.svg);
      mask-repeat: no-repeat;
      mask-position: top center;
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  .top-friend-info-text {
    font-size: 1.125rem;
    margin-left: 1rem;
  }
}
</style>
