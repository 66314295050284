<template>
  <b-col id="user-achievements-container" cols="12">
    <b-col cols="3" class="d-inline-flex align-items-center mb-3">
      <span v-if="!customAchievements" class="uppercase-subheadline">{{
        $t("lobby.navigation.achievements.web")
      }}</span
      ><InfoBadge
        v-if="$route.path === '/profile/achievements'"
        id="achievements-info"
        :headline-text="$t('achievements.headline')"
        :info-text="$t('info_badge.profile.achievements_headline')"
        faq-link="achievements"
      ></InfoBadge>
    </b-col>
    <b-col cols="12">
      <AchievementRow
        v-for="achievement in achievements"
        :key="achievement.id"
        :achievement="achievement"
        :inactive-achievements="inactiveAchievements"
        @topFriendClicked="fetchPublicUser"
    /></b-col>
  </b-col>
</template>

<script>
import AchievementRow from "./achievement-row.vue";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import InfoBadge from "@/components/base/info-badge.vue";

export default {
  name: "AchievementsOverview",
  components: {
    AchievementRow,
    InfoBadge,
  },
  mixins: [getPublicUserInfos],
  props: {
    customAchievements: { type: Array, required: false, default: null },
    rankedGames: { type: Array, default: null, required: false },
  },
  data() {
    return {
      inactiveAchievements: ["joinleaguex", "havegoldx", "top1slotbattlex"],
      error: false,
    };
  },
  computed: {
    achievements() {
      if (this.customAchievements) {
        return this.customAchievements;
      } else {
        return this.$store.state.achievements.userAchievements;
      }
    },
  },
  mounted() {
    if (this.achievements.length > 0) {
      this.$store.commit("setIsPageLoading", false);
    } else {
      this.error = true;
    }
  },
};
</script>
<style lang="scss" scoped>
#user-achievements-container {
  #achievements-faq {
    margin-top: -20px;
  }
}
</style>

<style lang="scss">
#user-achievements-container {
  position: relative;

  #achievements-list-head {
    z-index: 5;
  }

  .achievements-head-text {
    font-size: 16px;
    font-family: Cinzel-bold;
    color: #fff4b2;
    text-shadow: 1px 2px 2px black, 1px 2px 4px black;
    z-index: 6;
  }
}
</style>
