<template>
  <b-row align-h="start" align-v="center" class="top-game-row px-xl-4">
    <b-col
      class="game-section d-flex flex-column justify-content-center p-0 mr-2"
    >
      <div
        class="w-100 position-relative clickable"
        @click="
          () => {
            playSoundButtonCommon();
            $router.push(`/game/${game.trophy.game_id}`);
          }
        "
      >
        <img
          v-if="game.trophy.image_path"
          draggable="false"
          :src="game.trophy.image_path"
          class="w-100 position-relative"
        />
      </div> </b-col
    ><b-col class="friends-game-ranking">
      <!-- <b-row class="game-title-row pl-3"
        ><span
          class="text-uppercase bold d-flex flex-column justify-content-center"
          >Game Title</span
        ></b-row
      > -->

      <b-row class="trophy-data-row h-100"
        ><b-col xl="4" cols="12" class="trophy-data">
          <div class="w-100 h-50 d-flex align-items-center">
            <div class="w-50 d-flex flex-column align-items center">
              <span class="text-uppercase medium grey text-center">{{
                $t("achievements.top_games.your_trophies")
              }}</span>
              <div
                class="w-100 d-inline-flex align-items-center justify-content-center"
              >
                <span class="text-white bold blue-glow">{{
                  game.trophy.level
                }}</span>
                <div class="trophy-icon ml-3 bg-img"></div>
              </div>
            </div>
            <div class="w-50 d-flex flex-column align-items center text-center">
              <span class="text-uppercase medium grey">{{
                $t("tournaments.info.placement")
              }}</span>
              <div class="w-100 text-center">
                <span class="text-white bold blue-glow">{{
                  game.trophy.rank
                }}</span>
              </div>
            </div>
          </div>
          <div class="w-100 h-50 progress-section mt-2">
            <span class="text-uppercase medium grey">{{
              $t("achievements.top_games.next_trophy")
            }}</span>
            <div class="w-100 progress-bar position-relative mt-1">
              <div
                class="progress-bar-fill position-absolute"
                :style="{ width: trophyProgress + '%' }"
              ></div>
              <div class="trophy-icon position-absolute bg-img"></div>
            </div>
            <div class="progress-bar-description w-100 text-center mt-2">
              <span class="bold blue"> {{ trophyProgress }}% </span>
            </div>
          </div></b-col
        ><b-col cols="12" xl="8"
          ><div class="w-100 text-center">
            <span class="grey medium text-uppercase">{{
              $t("achievements.top_games.best_placements")
            }}</span>
          </div>
          <div class="friend-trophies w-100 d-inline-flex my-3">
            <b-col
              v-for="(place, index) in game.topX"
              :key="place.id + index"
              class="friend-place d-flex flex-column align-items-center"
            >
              <div
                v-if="game.topX[index].level > 0"
                class="friend-trophy-count w-100 d-inline-flex align-items-center justify-content-center"
              >
                <span class="text-white bold">{{
                  game.topX[index].level
                }}</span>
                <div class="trophy-icon bg-img"></div>
              </div>
              <div
                v-if="game.topX[index].level > 0"
                class="friend-avatar-container"
                @click="$emit('userClicked', game.topX[index])"
              >
                <img
                  draggable="false"
                  class="avatar-img to-center-abs"
                  :src="game.topX[index].user.image"
                />
                <div
                  class="friend-name-tooltip position-absolute d-flex align-items-center justify-content-center"
                >
                  <span class="text-uppercase text-white medium">
                    {{ shortenString(game.topX[index].user.name, 20) }}</span
                  >
                </div>
              </div>
              <img
                v-if="game.topX[index].level > 0"
                draggable="false"
                class="rank-badge"
                :src="
                  require(`@/assets/img/achievements/trophy-rank_${index}.png`)
                "
              />
            </b-col>
            <template v-if="game.topX.length < 5">
              <b-col v-for="index in 5 - game.topX.length" :key="index"></b-col>
            </template></div></b-col></b-row></b-col
  ></b-row>
</template>
<script>
export default {
  name: "TopGamesRow",
  props: {
    game: {
      type: Object,
      required: true,
    },
    fakeIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    trophyProgress() {
      return Math.ceil(this.game.trophy.progress.percentage);
    },
  },
};
</script>
<style lang="scss">
.top-game-row {
  min-height: 221px;
  margin-bottom: 16px;

  .game-section {
    max-width: 315px;
    max-height: 221px;
    img {
      max-width: 100%;
    }
  }

  .game-title-row {
    background-color: rgba(5, 13, 28, 0.31);
    height: 40px;
    span {
      font-size: 24px;
      color: #fff8b7;
    }
  }
  .trophy-data-row {
    background-color: $dark-blue-06;
    min-height: 181px;
    padding: 10px 10px 0 10px;

    .grey {
      font-size: 16px;
      color: #8e8e8e;
    }
    .trophy-icon {
      width: 32px;
      height: 32px;
      background-image: url(~@/assets/img/achievements/icn-trophy-32.png);
    }
    .trophy-data {
      .blue-glow {
        font-size: 36px;
        text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
          0 0 8px rgba(77, 229, 255, 0.6), 0 0 4px rgba(0, 94, 110, 0.8);
      }

      .progress-section {
        .progress-bar {
          height: 12px;
          background-image: linear-gradient(to bottom, #061319, #0d2e41);
          box-shadow: 0 3px 0 #074f5c;
          overflow: visible;
          .progress-bar-fill {
            height: 8px;
            left: 0;
            bottom: 2px;
            top: 2px;
            background-color: $light-blue;
            overflow: visible;
            filter: drop-shadow(0 0 8px rgba(77, 229, 255, 0.58))
              drop-shadow(0 0 8px rgba(77, 229, 255, 0.58))
              drop-shadow(0 0 4px rgba(77, 229, 255, 0.58));
          }
          .trophy-icon {
            width: 24px;
            height: 24px;
            bottom: -4px;
            right: -12px;
          }
        }
      }
    }

    .friend-trophy-count {
      span {
        font-size: 18px;
        text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
          0 0 8px rgba(77, 229, 255, 0.6), 0 0 4px rgba(0, 94, 110, 0.8);
      }
      .trophy-icon {
        width: 18px;
        height: 18px;
        margin-left: 7px;
      }
    }

    .friend-trophies {
      padding: 0 7%;
    }

    .friend-avatar-container {
      width: 64px;
      height: 64px;
      border-radius: 10%;
      z-index: 2;
      position: relative;
      cursor: pointer;
      overflow: visible;
      position: relative;
      margin-bottom: -5px;
      background-image: linear-gradient(to bottom, #061319, #0d2e41);
      box-shadow: 0 3px 0 #074f5c;

      &:hover {
        filter: $hover-shadow;
        .friend-name-tooltip {
          visibility: visible;
        }
      }
      .avatar-img {
        max-height: 100%;
      }
      .friend-name-tooltip {
        width: 128px;
        height: 32px;
        background-color: rgba(5, 13, 28, 0.8);
        border-radius: 4px;
        box-shadow: 2px 4px 12px black, 2px 4px 12px black, 0 0 4px $light-blue;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        left: 0;
        margin-left: -50%;
        font-size: 14px;
        z-index: 5;

        visibility: hidden;
        overflow-x: visible;
      }
    }
    .rank-badge {
      width: 64px;
      height: 49.5px;
      z-index: 5;
    }
  }
}
</style>
