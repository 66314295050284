<template>
  <b-col id="top-games-container" cols="12" class="h-100">
    <b-col cols="3" class="mb-3">
      <span class="uppercase-subheadline">{{
        $t("achievements.top_games")
      }}</span>
      <InfoBadge
        id="trophies-info"
        :headline-text="$t('achievements.top_games')"
        :info-text="$t('info_badge.profile.trophies_headline')"
        faq-link="achievements"
      ></InfoBadge
    ></b-col>

    <div v-if="rankedGames.length > 0">
      <TopGamesRow
        v-for="(game, index) in rankedGames"
        :key="game.id"
        :game="game"
        :fake-index="index + 1"
        @userClicked="handleClickOnUser"
      />
    </div>
  </b-col>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos";
import TopGamesRow from "./top-games-row.vue";
import InfoBadge from "@/components/base/info-badge.vue";

export default {
  name: "AchievementsTrophies",
  components: {
    TopGamesRow,

    InfoBadge,
  },
  mixins: [getPublicUserInfos],
  props: {
    rankedGames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: false,
    };
  },
  mounted() {
    if (this.rankedGames.length > 0) {
      this.$store.commit("setIsPageLoading", false);
    } else {
      this.error = true;
    }
  },

  methods: {
    handleClickOnUser(payload) {
      if (payload.user.deleted_at) return;
      this.fetchPublicUser(payload.userId);
    },
  },
};
</script>
