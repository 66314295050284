<template>
  <router-link
    v-if="(!locked && !germanOnly) || (!locked && germanOnly && germanActive)"
    :to="href"
    class="content-tab clickable d-flex align-items-center justify-content-center px-3 py-1 position-relative"
    :class="[specialClass, specialClass === 'profile' ? 'red-shadow' : '']"
    @click.native="handleClick"
  >
    <span class="label text-uppercase medium">
      {{ label }}
    </span>

    <div v-if="showBadge" class="news-badge">!</div>
  </router-link>
  <div
    v-else-if="locked && !germanOnly"
    class="content-tab position-relative disabled d-flex align-items-center justify-content-center px-3 py-1"
    :class="specialClass"
    @click="$emit('click-attempt')"
  >
    <div :class="{ active: $route.path === href }">
      <img
        id="tab-lock"
        draggable="false"
        alt="lock"
        src="@/assets/img/common/lock-gold.png"
      />
      <span
        class="label text-uppercase bold d-flex align-items-center justify-content-center"
      >
        {{ label }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavTab",
  props: {
    href: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    locked: {
      type: Boolean,
      default: false,
    },
    germanOnly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    lockedText: {
      type: String,
      default: null,
    },

    showBadge: {
      type: Boolean,
      default: false,
    },
    specialClass: {
      type: String,
      default: "",
    },
  },

  computed: {
    germanActive() {
      return this.$store.state.locale === "de";
    },
    badgeNumber() {
      if (this.href === "/friends") {
        return this.$store.state.user.openFriendRequests.length;
      } else if (this.href === "/friends/chat") {
        return this.$store.state.chat.unreadMessages;
      } else {
        return 0;
      }
    },
  },
  methods: {
    handleClick() {
      this.playSoundTab();
      this.$emit("tab-clicked", this.href);
    },
  },
};
</script>

<style scoped lang="scss">
.content-tab {
  height: 2rem;
  min-width: 10rem;
  z-index: 0;
  margin-right: 0.625rem;
  background-image: linear-gradient(
    to bottom,
    rgba(22, 63, 81, 0.5),
    rgba(5, 13, 28, 0.5)
  );
  font-size: 1rem;
  &.profile {
    height: 3.375rem;
    width: 12.438rem;
    background: url("~@/assets/img/profile/nav-btn-profile.png") center/100%
      100% no-repeat;
    .label {
      color: #d47676;
      font-size: 1rem;
    }
    @media (max-width: 1650px) {
      height: 3.175rem;
      width: 10.438rem;
    }
  }
  &.tournament {
    width: 17.188rem;
    height: 4rem;
    background: url("~@/assets/img/tournament/nav-btn-tourney.png") center/100%
      100% no-repeat;
    .label {
      color: #5499a4;
      font-size: 1.313rem;
    }
  }
  &.disabled {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }
  &.router-link-exact-active {
    background-image: linear-gradient(
      to bottom,
      rgb(22, 63, 81),
      rgb(5, 13, 28)
    );
    pointer-events: none;
    &.profile {
      background: url("~@/assets/img/profile/nav-btn-profile_active.png")
        center/100% 100% no-repeat;
    }
    &.tournament {
      width: 17.188rem;
      height: 4rem;
      background: url("~@/assets/img/tournament/nav-btn-tourney.png")
        center/100% 100% no-repeat;
      .label {
        color: white;
        text-shadow: 0 0 0.5rem rgba(77, 229, 255, 0.8);
      }
    }
    .label {
      color: white;
      text-shadow: 0 0 0.5rem rgba(77, 229, 255, 0.8);
    }
  }
  .notification-badge {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0.813rem;
    bottom: 1.25rem;
    border-radius: 100%;
    &.orange {
      background-color: #ffa900;
    }
    &.green {
      background-color: #2da304;
    }
  }

  .label {
    color: #5499a4;
    font-size: 0.875rem;
  }
  #tab-lock {
    position: absolute;
    max-height: 90%;
    right: 5%;
    top: 0;
  }
}
</style>
