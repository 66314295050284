<template>
  <div class="tabbed-page">
    <div class="content-binder container position-relative">
      <slot name="top-tabbed-content"> </slot>

      <b-row align-h="center" class="nav-container text-normal">
        <b-nav class="tabbed-nav d-inline-flex align-items-center">
          <slot name="nav-tabs"> </slot>
          <slot name="additional-tab-content"></slot>
        </b-nav>
      </b-row>
      <b-row no-gutters class="main-content-row text-normal">
        <slot name="main-content"> </slot>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabbedPage",
};
</script>
<style lang="scss">
.tabbed-page {
  .content-binder {
    padding: 0 32px 0 32px;
    .nav-container {
      z-index: 0;
      position: relative;
      margin-bottom: 23px;
    }
  }
}
</style>
